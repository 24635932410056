import suklWidgetConfig from '@nsf/sukl-widget/config/cz/app.config.mjs'

export default {
  footer: {
    certificates: [
      ...suklWidgetConfig.codes.map((code) => ({
        displayInContext: {
          productAttributeSets: suklWidgetConfig.medicalProdIds,
        },
        image: {
          url: '/images/footer/sukl-checkout.png',
          alt: 'Ověření internetových stránek a lékárny',
          width: 165,
          height: 138,
        },
        link: `https://prehledy.sukl.cz/prehledy.html#/lekarny/${code}?verify=true`,
      })),
      ...suklWidgetConfig.codes.map((code) => ({
        displayInContext: {
          productAttributeSets: suklWidgetConfig.vetProdIds,
        },
        image: {
          url: '/images/footer/vet-sukl-checkout.png',
          alt: 'Ověření internetových stránek a lékárny',
          width: 165,
          height: 138,
        },
        link: `http://www.uskvbl.cz/cs/inspekce/lekarny/seznamy/detail?Id=${code}`,
      })),
    ],
    links: [
      {
        url: '/vse-o-nakupu/vseobecne-obchodni-podminky',
        text: 'Všeobecné obchodní podmínky',
      },
      {
        url: '/vse-o-nakupu/rezervacni-podminky',
        text: 'Rezervační podmínky',
      },
      {
        url: '/vse-o-nakupu/ochrana-ou',
        text: 'Ochrana osobních údajů v e-shopu',
      },
    ],
  },
}
