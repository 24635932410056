export default {
  allow: [
    ...process.env.ROBOTS_ALLOW?.split('|') || [],
    '*&menu=*',
    '*&rating=*',
    '*?amp*',
    '*?amp%3Brefresh*',
    '*?cjdata*',
    '*?fbclid*',
    '*?fresh*',
    '*?gbraid*',
    '*?gclid*',
    '*?menu=*',
    '*?network*',
    '*?page*',
    '*?p=*',
    '*?ppcbee-adtext-variant*',
    '*?rating=*',
    '*?refresh*',
    '*?refreshing*',
    '*?ruleid*',
    '*?sku=*',
    '*?utm*',
    '*?y_source*',
    '/_i/*',
    '/i18n/*'
  ],
  disallow: [
    ...process.env.ROBOTS_DISALLOW?.split('|') || [],
    '/*?*',
    '*/_e*',
    '*/graphql*',
    '/pokladna/*',
    '/oblibene-produkty$',
    '/kartavyhod/muj-ucet$',
    '/kartavyhod/moje-objednavky$',
    '/kartavyhod/editace-udaju$',
    '/kartavyhod/moje-nabidky$',
    '/kartavyhod/moje-oblibene$',
    '/kartavyhod/moji-hlidaci-psi$',
    '/kartavyhod/baby-club$',
  ],
}
